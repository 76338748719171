import React from "react";
import "./style.scss";

const Button = ({ imageID, setImageID, MAX_IMG_COUNT, whatToDo }) => {
  const handleClick = () => {
    if (whatToDo === "next") {
      setImageID(imageID + 1);

      if (imageID >= MAX_IMG_COUNT) setImageID(1);
    } else if (whatToDo === "prev") {
      setImageID(imageID - 1);

      if (imageID <= 1) setImageID(MAX_IMG_COUNT);
    } else {
      console.log(whatToDo, "not defined");
    }
  };

  return (
    <div className="button" onClick={handleClick}>
      <div className="arrow"></div>
    </div>
  );
};

export default Button;
