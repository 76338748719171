import React from "react";
import "./style.scss";

const Info = (props) => (
  <div className="about-business">
    <h1>Trochę o firmie...</h1>
    <p>
      Firma Anita Style specjalizuje się w tworzeniu kompozycji z kwiatów sztucznych i handlu
      artykułami dekoracyjnymi od 2015 roku. Nasza oferta obejmuje wysokiej jakości kompozycje
      kwiatowe i różnorodne artykuły dekoracyjne, które dodają uroku i elegancji do wnętrz.
    </p>
    <p>
      Tworzymy piękne kompozycje z kwiatów sztucznych na różne okazje, takie jak wesela, urodziny,
      komunie oraz kompozycje nagrobne. Nasze projekty są starannie zaprojektowane, aby wprowadzać
      świeżość i harmonię do wnętrz. W sklepie Anita Style oferujemy również różnorodne artykuły
      dekoracyjne, takie jak ozdoby do wnętrz, wazony, lampiony i kompozycje, które można dostosować
      do różnych stylów.
    </p>
    <p>
      Nasza firma kładzie duży nacisk na jakość produktów, dlatego współpracujemy z renomowanymi
      dostawcami, aby zapewnić trwałość i estetykę naszych kompozycji kwiatowych oraz artykułów
      dekoracyjnych. Indywidualne podejście do klienta, profesjonalne doradztwo i dbałość o
      szczegóły są dla nas priorytetem.
    </p>
    <p>
      Anita Style to idealne miejsce dla osób poszukujących pięknych i trwałych kompozycji
      kwiatowych oraz oryginalnych artykułów dekoracyjnych. Zapraszamy do naszego sklepu i
      skorzystania z naszych usług, aby dodać wyjątkowego uroku i atmosfery do swoich przestrzeni.
    </p>
  </div>
);

export default Info;
