import { configureStore } from "@reduxjs/toolkit";
import OpeningHours from "./Store/OpeningHours";
import firmData from "./Store/FirmData";

export default configureStore({
  reducer: {
    openingHours: OpeningHours,
    firmData: firmData,
  },
});
