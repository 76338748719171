import React from "react";
import "./style.scss";
import { useSelector } from "react-redux";

const FirmData = (props) => {
  const { name, nip, address } = useSelector((state) => state.firmData);
  const { city, postCode, street, homeNr } = address;

  return (
    <div className="box firmData">
      <p className="title">Dane firmy</p>
      <p>{name}</p>
      <p>
        ul. {street} {homeNr}
      </p>
      <p>
        {city}, {postCode}
      </p>
      <p>NIP: {nip}</p>
    </div>
  );
};

export default FirmData;
