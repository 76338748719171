import React from "react";
import "./style.scss";

const Creator = (props) => (
  <div className="creator">
    Projekt i wykonanie: <a href="https://deejayadi.github.io/deejayadi.pl/">DeeJay Adi</a>
  </div>
);

export default Creator;
