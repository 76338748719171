import React from "react";
import Gallery from "./Components/Gallery";
import Info from "./Components/Info";
import Socialmedia from "./Components/Socialmedia";

const Main = (props) => (
  <>
    <Gallery />
    <Info />
    <Socialmedia />
  </>
);

export default Main;
