import React from "react";
import "./style.scss";

const Logo = (props) => (
  <a href="/" className="logo">
    Anita Style
  </a>
);

export default Logo;
