import React, { useState } from "react";
import "./style.scss";
import Hamburger from "./Components/Hamburger";
import Logo from "./Components/Logo";

const Header = (props) => {
  const [active, setActive] = useState(false);
  const switchActive = () => setActive(!active);

  return (
    <header>
      <Hamburger active={active} switchActive={switchActive} />
      <Logo />
    </header>
  );
};

export default Header;
