import React from "react";
// import pdf from "./katalog_figurki.pdf";
import "./style.scss";

const Catalog = (props) => (
  <>
    <p>Do dopracowania jest ta sekcja</p>
    {/* <embed
      className="catalog"
      src={pdf}
      type="application/pdf"
      width={window.innerWidth}
      height={window.innerHeight}
    /> */}
  </>
);

export default Catalog;
