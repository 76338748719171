import React, { useState } from "react";
import "./style.scss";
import Button from "./Components/Button";

const Gallery = (props) => {
  const [imageID, setImageID] = useState(1);
  const MAX_IMG_COUNT = 5;

  return (
    <div className="gallery">
      <Button
        imageID={imageID}
        setImageID={setImageID}
        MAX_IMG_COUNT={MAX_IMG_COUNT}
        whatToDo="prev"
      />
      <img src={`/img/slider/img${imageID}.jpg`} alt="" />
      <Button
        imageID={imageID}
        setImageID={setImageID}
        MAX_IMG_COUNT={MAX_IMG_COUNT}
        whatToDo="next"
      />
    </div>
  );
};

export default Gallery;
