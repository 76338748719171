import React from "react";
import "./style.scss";

const Copyright = (props) => {
  const year = new Date().getFullYear();

  return (
    <div className="copyright">
      &copy; {year} <a href="/">Anita Style</a>
    </div>
  );
};

export default Copyright;
