import { createSlice } from "@reduxjs/toolkit";

export const firmData = createSlice({
  name: "firmData",
  initialState: {
    name: "Anita Style",
    nip: "7491051966",
    address: {
      city: "Sukowice",
      postCode: "47-260",
      street: "Zwycięstwa",
      homeNr: "22",
    },
    contact: [
      { key: "e-mail", value: "kontakt@anitastyle.pl" },
      { key: "phone-nr", value: "+48 504 810 274" },
    ],
    socialMedia: [
      { type: "facebook", link: "https://facebook.com/sklepanitastyle" },
      { type: "instagram", link: "https://www.instagram.com/sklep_anita_style/" },
    ],
  },
});

export default firmData.reducer;
