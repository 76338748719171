import { createSlice } from "@reduxjs/toolkit";

export const openingHours = createSlice({
  name: "openingHours",
  initialState: {
    monday: { label: "Poniedziałek", open: "9:00", close: "17:00" },
    tuesday: { label: "Wtorek", open: "9:00", close: "17:00" },
    wednesday: { label: "Środa", open: "9:00", close: "17:00" },
    thursday: { label: "Czwartek", open: "9:00", close: "17:00" },
    friday: { label: "Piątek", open: "9:00", close: "15:00" },
    saturday: { label: "Sobota", open: "9:00", close: "13:00" },
    sunday: { label: "Niedziela", closed: true },
  },
});

export default openingHours.reducer;
