import React, { Component } from "react";
import "./App.scss";
import Footer from "./Components/Footer";
import { Route, Routes } from "react-router-dom";
import Catalog from "./Pages/Catalog";
import Main from "./Pages/Main";
import Header from "./Components/Header";

class App extends Component {
  render() {
    return (
      <>
        <Header />
        <Routes>
          <Route exact path="/" element={<Main />} />
          <Route path="/catalog" element={<Catalog />} />
        </Routes>
        <Footer />
      </>
    );
  }
}

export default App;
