import React from "react";
import "./style.scss";

const Hamburger = ({ active, switchActive }) => (
  <div className={active ? "hamburger active" : "hamburger"} onClick={switchActive}>
    <div></div>
    <div></div>
    <div></div>
  </div>
);

export default Hamburger;
