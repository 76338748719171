import React from "react";
import "./style.scss";
import emailIcon from "./Assets/mail.png";
import phoneIcon from "./Assets/old-typical-phone.png";
import { useSelector } from "react-redux";

const Contact = (props) => {
  const { contact } = useSelector((state) => state.firmData);

  return (
    <div className="box contact">
      <p className="title">Kontakt</p>
      {contact.map((d, i) => (
        <ContactDataRow key={i} type={d.key} value={d.value} />
      ))}
    </div>
  );
};

const ContactDataRow = ({ type, value }) => {
  if (type === "e-mail") {
    return (
      <a href={"mailto:" + value}>
        <img className="icon" src={emailIcon} alt="" />

        {value}
      </a>
    );
  } else if (type === "phone-nr") {
    return (
      <a href={"tel:" + value}>
        <img className="icon" src={phoneIcon} alt="" />
        {value}
      </a>
    );
  } else {
    return null;
  }
};

export default Contact;
