import React from "react";
import "./style.scss";
import { useSelector } from "react-redux";

const Socialmedia = (props) => {
  const socialMediaList = useSelector((state) => state.firmData.socialMedia);

  return (
    <div className="socialmedia">
      <h1>Zaobserwuj nasze profile!</h1>

      <div className="list">
        {socialMediaList.map((sm, index) => (
          <a
            key={index}
            href={sm.link}
            className={sm.type}
            title={sm.type}
            target="_blank"
            rel="noreferrer"
          >
            {" "}
          </a>
        ))}
      </div>
    </div>
  );
};

export default Socialmedia;
