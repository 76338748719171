import React from "react";
import "./style.scss";
import Copyright from "./Components/Copyright";
import Creator from "./Components/Creator";
import FirmData from "./Components/FirmData";
import OpeningHours from "./Components/OpeningHours";
import Map from "./Components/Map";
import Contact from "./Components/Contact";

const Footer = (props) => (
  <footer>
    <div className="info">
      <FirmData />
      <Contact />
      <OpeningHours />
      <Map />
    </div>
    <div>
      <Creator />
      <Copyright />
    </div>
  </footer>
);

export default Footer;
