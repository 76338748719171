import React from "react";
import "./style.scss";

const Map = (props) => (
  <div className="box map">
    <p className="title">Jak dojechać?</p>
    <iframe
      src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d10202.027777302254!2d18.1706743!3d50.2637924!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47111371f4b48adf%3A0x5ea51d3cad2d1555!2sAnita%20Style!5e0!3m2!1sen!2spl!4v1682621259898!5m2!1sen!2spl"
      style={{ border: 0, width: "100%", height: "100%" }}
      allowFullScreen=""
      loading="lazy"
      title="map"
      referrerPolicy="no-referrer-when-downgrade"
    ></iframe>
  </div>
);

export default Map;
