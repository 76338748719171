import React from "react";
import "./style.scss";
import { useSelector } from "react-redux";

const OpeningHours = (props) => {
  const data = useSelector((state) => state.openingHours);
  const date = new Date().getDay();

  return (
    <div className="box openinghours">
      <p className="title">Godziny otwarcia</p>
      {Object.keys(data).map((key, i) => {
        const { label, open, close, closed } = data[key];
        const className = date - 1 === i ? "active" : "";

        if (closed)
          return (
            <p className={className} key={i}>
              {label}: Zamknięte
            </p>
          );

        return (
          <p className={className} key={i}>
            {label}: {open}-{close}
          </p>
        );
      })}
    </div>
  );
};

export default OpeningHours;
